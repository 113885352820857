<template>
	<div class="bg-white rounded-md shadow">
		<div
			class="flex text-sm"
			role="tablist"
			aria-label="Recent, Favorite, and Most Viewed Videos Widget">
			<button
				id="recentVideosBtn"
				ref="recentTab"
				:tabindex="currentTab === 'recent' ? '0' : '-1'"
				class="focus-visible:bg-blue-50 text-center cursor-pointer py-3 w-22 rounded-tl-md focus-visible:z-20"
				:class="{
					'bg-white font-bold': currentTab === 'recent',
					'bg-gray-200': currentTab !== 'recent',
				}"
				@click="currentTab = 'recent'"
				@keydown="handleKeyDown"
				:aria-selected="currentTab === 'recent'"
				aria-controls="recentVideos">
				Recent
			</button>
			<button
				id="favoriteVideosBtn"
				ref="favoritesTab"
				:tabindex="currentTab === 'favorites' ? '0' : '-1'"
				class="focus-visible:bg-blue-50 text-center cursor-pointer border-r border-l border-gray-100 py-3 w-26 focus-visible:z-20"
				:class="{
					'bg-white font-bold': currentTab === 'favorites',
					'bg-gray-200': currentTab !== 'favorites',
				}"
				@click="currentTab = 'favorites'"
				@keydown="handleKeyDown"
				:aria-selected="currentTab === 'favorites'"
				aria-controls="favoriteVideos">
				Favorites
			</button>
			<button
				id="topVideosBtn"
				ref="topTab"
				:tabindex="currentTab === 'top' ? '0' : '-1'"
				class="focus-visible:bg-blue-50 flex-1 text-center cursor-pointer py-3 px-2 rounded-tr-md focus-visible:z-20"
				:class="{
					'bg-white font-bold': currentTab === 'top',
					'bg-gray-200': currentTab !== 'top',
				}"
				@click="currentTab = 'top'"
				@keydown="handleKeyDown"
				:aria-selected="currentTab === 'top'"
				aria-controls="topVideos">
				Most Viewed
			</button>
		</div>
		<div
			role="tabpanel"
			v-show="currentTab === 'recent'"
			id="recentVideos"
			aria-labelledby="recentVideosBtn">
			<div v-if="loaded">
				<ul v-if="lastViewed.length" class="divide-y">
					<li
						v-for="lesson in lastViewed"
						class="border-gray-100"
						:key="lesson.id">
						<button
							class="w-full flex hover:bg-cyan-50 hover:no-underline border-b last:border-b-0 border-gray-100 space-x-4 p-4 cursor-pointer"
							v-on:click="
								navigateToLesson(
									lesson.uuid,
									lesson.module_div_id
								)
							">
							<div
								class="flex items-start justify-center flex-shrink-0 flex-grow-0 w-21">
								<div class="aspect-w-3 aspect-h-2 w-full">
									<img
										v-if="lesson.wistia_thumbnail_url"
										class="object-cover rounded-lg ring-2 ring-gray-300"
										:src="lesson.wistia_thumbnail_url"
										:alt="lesson.title" />
									<img
										v-else
										class="object-cover rounded-lg ring-2 ring-gray-300"
										src="https://s3.amazonaws.com/files.cloverlearning.com/images/default/clover-recent-video-default-thumbnail.jpg"
										:alt="lesson.title" />
								</div>
							</div>
							<div class="text-sm text-left">
								<div class="font-semibold leading-6">
									{{ lesson.title }}
								</div>
								<div class="leading-9/2">
									{{ lesson.subtitle }}
								</div>
								<div class="text-gray-500 text-xs mt-2">
									Viewed {{ lesson.last_viewed_at }}
								</div>
							</div>
						</button>
					</li>
				</ul>
				<div
					v-else
					class="flex flex-col justify-center items-center space-y-4 py-8 px-16 text-center">
					<div class="py-4">
						<SvgIcon
							class="text-gray-200"
							icon="tv"
							size="xl2"
							weight="light" />
					</div>
					<div class="text-sm">No recently viewed lessons</div>
				</div>
			</div>
			<div v-else>
				<Loader height="h-52" :padding="false" />
			</div>
		</div>
		<div
			aria-labelledby="favoriteVideosBtn"
			role="tabpanel"
			v-show="currentTab === 'favorites'"
			id="favoriteVideos">
			<div v-if="loaded">
				<ul v-if="favorites.length" class="divide-y">
					<li
						v-for="lesson in favorites"
						class="border-gray-100"
						:key="lesson.id">
						<button
							class="w-full flex hover:bg-cyan-50 hover:no-underline p-4 space-x-4 cursor-pointer"
							v-on:click="
								navigateToLesson(
									lesson.uuid,
									lesson.module_div_id
								)
							">
							<div
								class="flex items-start justify-center flex-shrink-0 flex-grow-0 w-21">
								<div class="aspect-w-3 aspect-h-2 w-full">
									<img
										v-if="lesson.wistia_thumbnail_url"
										class="object-cover rounded-lg ring-2 ring-gray-300"
										:src="lesson.wistia_thumbnail_url"
										:alt="lesson.title" />
									<img
										v-else
										class="object-cover rounded-lg ring-2 ring-gray-300"
										src="https://s3.amazonaws.com/files.cloverlearning.com/images/default/clover-recent-video-default-thumbnail.jpg"
										:alt="lesson.title" />
								</div>
							</div>
							<div class="text-sm text-left">
								<div class="font-semibold leading-6">
									{{ lesson.title }}
								</div>
								<div class="leading-9/2">
									{{ lesson.subtitle }}
								</div>
								<div class="text-gray-500 text-xs mt-2">
									Favorited {{ lesson.created_at }}
								</div>
							</div>
						</button>
					</li>
					<li class="text-center py-3">
						<a class="text-sm cta" :href="savedItemsUrl">
							View all
						</a>
					</li>
				</ul>
				<div
					v-else
					class="flex flex-col justify-center items-center space-y-4 p-8">
					<div class="py-4">
						<SvgIcon
							class="text-gray-200"
							icon="star"
							size="xl2"
							weight="light" />
					</div>
					<div class="text-sm">No favorites</div>
				</div>
			</div>
			<div v-else>
				<Loader height="h-52" :padding="false" />
			</div>
		</div>
		<div
			role="tabpanel"
			v-show="currentTab === 'top'"
			id="topVideos"
			aria-labelledby="topVideosBtn">
			<div v-if="loaded">
				<ul v-if="mostViewed.length" class="divide-y">
					<li
						v-for="lesson in mostViewed"
						class="border-gray-100"
						:key="lesson.id">
						<button
							class="w-full flex hover:bg-cyan-50 hover:no-underline space-x-4 p-4 cursor-pointer"
							v-on:click="
								navigateToLesson(
									lesson.uuid,
									lesson.module_div_id
								)
							">
							<div
								class="flex items-start justify-center flex-shrink-0 flex-grow-0 w-21">
								<div class="aspect-w-3 aspect-h-2 w-full">
									<img
										v-if="lesson.wistia_thumbnail_url"
										class="object-cover rounded-lg ring-2 ring-gray-300"
										:src="lesson.wistia_thumbnail_url"
										:alt="lesson.title" />
									<img
										v-else
										class="object-cover rounded-lg ring-2 ring-gray-300"
										src="https://s3.amazonaws.com/files.cloverlearning.com/images/default/clover-recent-video-default-thumbnail.jpg"
										:alt="lesson.title" />
								</div>
							</div>
							<div class="text-sm text-left">
								<div class="font-semibold leading-6">
									{{ lesson.title }}
								</div>
								<div class="leading-9/2">
									{{ lesson.subtitle }}
								</div>
							</div>
						</button>
					</li>
				</ul>
				<div v-else>
					<span>&nbsp;</span>
				</div>
			</div>
			<div v-else>
				<Loader height="h-52" :padding="false" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		savedItemsUrl: {
			required: true,
			type: String,
		},
		statisticsUrl: {
			required: true,
			type: String,
		},
	},
	data(): any {
		return {
			currentTab: "recent",
			favorites: null,
			lastViewed: null,
			loaded: false,
			mostViewed: null,
		};
	},
	methods: {
		imageUrl(slug) {
			return (
				"https://s3.amazonaws.com/files.cloverlearning.com/images/lessons/thumbnails/" +
				slug +
				".jpg"
			);
		},
		navigateToLesson(uuid, slug) {
			// @ts-ignore
			this.$events.$emit("highlightLesson", {
				uuid: uuid,
				module_div_id: slug,
			});
			// @ts-ignore
			document
				.getElementById(slug)
				.scrollIntoView({ behavior: "smooth", block: "center" });
		},
		handleKeyDown(event: KeyboardEvent) {
			if (event.key === "ArrowLeft") {
				this.navigateLeft();
			} else if (event.key === "ArrowRight") {
				this.navigateRight();
			} else if (event.key === "Home") {
				this.currentTab = "recent";
				this.$refs.recentTab.focus();
			} else if (event.key === "End") {
				this.currentTab = "top";
				this.$refs.topTab.focus();
			}
		},
		navigateLeft() {
			if (this.currentTab === "recent") {
				this.currentTab = "top";
				this.$refs.topTab.focus();
			} else if (this.currentTab === "favorites") {
				this.currentTab = "recent";
				this.$refs.recentTab.focus();
			} else if (this.currentTab === "top") {
				this.currentTab = "favorites";
				this.$refs.favoritesTab.focus();
			}
		},
		navigateRight() {
			if (this.currentTab === "recent") {
				this.currentTab = "favorites";
				this.$refs.favoritesTab.focus();
			} else if (this.currentTab === "favorites") {
				this.currentTab = "top";
				this.$refs.topTab.focus();
			} else if (this.currentTab === "top") {
				this.currentTab = "recent";
				this.$refs.recentTab.focus();
			}
		},
	},
	created() {
		axios.get(this.statisticsUrl).then((response) => {
			this.favorites = response.data.favorites;
			this.lastViewed = response.data.last_viewed;
			this.mostViewed = response.data.most_viewed;
			this.loaded = true;
		});
	},
});
</script>
